import {RouteInfo} from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard/dashboard1',
    title: 'Dashboard',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '', title: 'Purchase', icon: 'icon-basket-loaded', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['view_purchase_order', 'view_good_received_note'],
    submenu: [
      {
        path: '',
        title: 'Purchase Order',
        icon: '',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        allowPermissions: ['view_purchase_order'],
        submenu: [
          {
            path: '/purchase-order/purchase-order/PRIOR_ORDER_PO',
            title: 'Prior PO',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_purchase_order']
          },
          {
            path: '/purchase-order/purchase-order/INVOICE_ORDER_PO',
            title: 'Invoice Order PO',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_purchase_order']
          },
          {
            path: '/purchase-order/purchase-order/CONSIGNMENT_PO',
            title: 'Consignment PO',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_purchase_order']
          },
          {
            path: '/purchase-order/purchase-order/SEND_INVENTORY',
            title: 'Send Inventory',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_purchase_order']
          }
        ]
      },
      {
        path: '/good-received-note/good-received-note',
        title: 'Good Received Note',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_good_received_note']
      }
    ]
  },

  {
    path: '', title: 'Invoice Settlement', icon: 'fa fa-file-text-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['add_purchase_invoice', 'add_purchase_invoice', 'view_purchase_invoice', 'add_purchase_invoice_settlement', 'view_purchase_invoice_settlement'],
    submenu: [
      {
        path: '/purchase-invoice/create-purchase-invoice',
        title: 'Client Invoices Upload',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_purchase_invoice']
      },
      {
        path: '/purchase-invoice/consignment-invoice-upload',
        title: 'Consignment Invoice Upload',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_purchase_invoice']
      },
      {
        path: '/purchase-invoice/purchase-invoice',
        title: 'Client Invoices',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_purchase_invoice']
      },
      {
        path: '/purchase-invoice-settlement/create',
        title: 'New Settlement',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_purchase_invoice_settlement']
      },
      {
        path: '/purchase-invoice-settlement/list',
        title: 'Settlements',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_purchase_invoice_settlement']
      },
    ]
  },

  {
    path: '', title: 'Sales', icon: 'ft-shopping-cart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/sales-invoice/sales-invoice',
        title: 'Invoices',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_sales_invoice']
      },
      {
        path: '/sales-invoice/create-sales-invoice',
        title: 'Create Invoices',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_sales_invoice']
      },
      {
        path: '/sales-invoice/upload-sale-invoice-excel',
        title: 'Upload Excel',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_sales_invoice']
      }
    ],
    allowPermissions: ['view_sales_invoice', 'add_sales_invoice']
  },

  {
    path: '', title: 'Sales Return', icon: 'ft-rotate-ccw', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['view_return_ticket', 'view_credit_note', 'add_return_ticket', 'view_shipping_claim'],
    submenu: [
      {
        path: '/return-ticket/return-tickets-list',
        title: 'Return Tickets',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_return_ticket']
      },
      {
        path: '/credit-note/credit-note-list',
        title: 'Credit Notes/Refunds',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_credit_note']
      },
      {
        path: '/return-ticket/manual-return-ticket-create',
        title: 'Manual Return Ticket',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_return_ticket']
      },
      {
        path: '/return-ticket/shipping-claims-list',
        title: 'Shipping Claims',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_shipping_claim']
      },
    ]
  },

  {
    path: '', title: 'Order Fulfillment', icon: 'fa fa-file-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['ship_list', 'view_draft'],
    submenu: [
      {
        path: '/sales-invoice/pick-list',
        title: 'Pick List',
        icon: '',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: []
      },
      {
      path: '/sales-invoice/sales-invoice-process',
      title: 'Ship List',
      icon: '',
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      allowPermissions: ['ship_list']
    },
      {
        path: '/draft/draft-list',
        title: 'Ship List - Saved Drafts',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_draft']
      }]
  },

  {
    path: '', title: 'RMA', icon: 'fa fa-retweet', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['view_rma', 'add_rma'],
    submenu: [
      {
        path: '/rma/rma',
        title: 'RMA',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_rma']
      },
      {
        path: '/rma/create-rma',
        title: 'Create RMA',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_rma']
      },
    ]
  },
  {
    path: '', title: 'Inventory', icon: 'fa fa-cubes', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['view_inventory_summary', 'view_inventory_list', 'view_fba_inventory', 'view_manual_stock'],
    submenu: [
      {
        path: '/inventory/summary',
        title: 'Summary',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_inventory_summary']
      },
      {
        path: '/inventory/list',
        title: 'List',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_inventory_list']
      },
      {
        path: '/inventory/amazon-fba-inventory',
        title: 'FBA Inventory',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_fba_inventory']
      },
      {
        path: '/manual-stock/manual-stock-list',
        title: 'Manual Stock Override',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_manual_stock']
      }
    ]
  },
  {
    path: '', title: 'Inventory Transfer', icon: 'ft-activity', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['view_inventory_transfer', 'add_inventory_transfer'],
    submenu: [
      {
        path: '/inventory-transfer/transfer-list',
        title: 'Transfers',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_inventory_transfer']
      },
      {
        path: '/inventory-transfer/transfer-new',
        title: 'New Transfer ',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['add_inventory_transfer']
      },
    ]
  },

  {
    path: '', title: 'Reports', icon: 'icon-bar-chart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['stock_and_value_report', 'daily_control_and_ship_list_report', 'daily_inventory_level_report'],
    submenu: [
      {
        path: '/reports/stock-control-and-value',
        title: 'Stock And Value',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['stock_and_value_report']
      },
      {
        path: '',
        title: 'Fifo',
        icon: '',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        allowPermissions: ['stock_and_value_report'],
        submenu: [
          {
            path: '/reports/fifo-stock-process',
            title: 'Fifo Stock Process',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['stock_and_value_report']
          },
          {
            path: '/reports/fifo-stock-value',
            title: 'Fifo Stock And Value',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['stock_and_value_report']
          }
        ]
      },
      {
        path: '/reports/daily-control-and-ship-list',
        title: 'Daily Control and Ship List',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['daily_control_and_ship_list_report']
      },
      {
        path: '/reports/daily-inventory-level',
        title: 'Daily Inventory Level',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['daily_inventory_level_report']
      },
    ]
  },

  {
    path: '', title: 'Shipping Fulfillment', icon: 'fa fa-truck', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['ebay_stock_update', 'shopify_stock_update', 'amazon_stock_update', 'shipping_fulfilment', 'marketplace_fulfilment', 'amazon_fulfilment'],
    submenu: [
      {
        path: '', title: 'Amazon', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        allowPermissions: ['amazon_fulfilment', 'amazon_stock_update'],
        submenu: [
          {
            path: '/api/amazon-fulfillment',
            title: 'Amazon Fulfilment',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['amazon_fulfilment']
          },
          {
            path: '/api/amazon-tracking-update',
            title: 'Amazon Fulfillment (VYGS)',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['amazon_stock_update']
          }
        ]
      },
      {
        path: '/api/dhl-fulfillment',
        title: 'DHL Fulfillment',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/api/royal-mail-fulfillment',
        title: 'Royal Mail Fulfillment',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/api/royal-mail',
        title: 'Royal Mail Sync',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/api/shipping-career-fulfillment',
        title: 'DPD / Parcel Force Fulfillment',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['shipping_fulfilment']
      },
      {
        path: '/api/marketplace-fulfillment',
        title: 'Marketplace Fulfilment',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['marketplace_fulfilment']
      },
    ]
  },

  {
    path: '', title: 'Stock Update', icon: 'fa fa-pencil-square-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['ebay_stock_update', 'shopify_stock_update', 'amazon_stock_update', 'shipping_fulfilment', 'marketplace_fulfilment', 'amazon_fulfilment'],
    submenu: [
      {
        path: '/api/amazon-stock-update',
        title: 'Amazon Stock Update',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['amazon_stock_update']
      },
      {
        path: '/api/ebay-stock-update',
        title: 'Ebay Stock Update',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['ebay_stock_update']
      },
      {
        path: '/api/shopify-stock-update',
        title: 'Shopify Stock Update',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['shopify_stock_update']
      },

      {
        path: '/api/samsung-stock-update',
        title: 'Samsung Stock Update',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['ebay_stock_update']
      },

    ]
  },
  {
    path: '',
    title: 'WMS',
    icon: 'icon-home',
    class: 'has-sub',
    allowPermissions: ['view_warehouse','process_bundle_task'],
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '',
        title: 'Warehouse',
        icon: '',
        allowPermissions: ['view_warehouse'],
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/warehouse/warehouse',
            title: 'Warehouse',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_warehouse']
          },
          {
            path: '/warehouse/warehouse-advanced-config',
            title: 'Advanced Config',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_warehouse']
          },
          {
            path: '/warehouse/warehouse-advanced-config-view',
            title: 'Advanced Config View',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_warehouse']
          }
        ]
      },
      {
        path: '',
        title: 'Bundling',
        icon: '',
        class: 'has-sub',
        badge: '',
        allowPermissions: ['view_warehouse','process_bundle_task'],
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/warehouse/fmcg/task-list',
            title: 'Task List',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            allowPermissions: ['view_warehouse'],
            submenu: []
          },
          {
            path: '/warehouse/fmcg/my-task-list',
            title: 'My Tasks',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            allowPermissions: ['process_bundle_task'],
            submenu: []
          }
        ]
      },
      {
        path: '',
        title: 'Inventory',
        icon: '',
        class: 'has-sub',
        allowPermissions: ['view_warehouse'],
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/warehouse/warehouse-inventory-stock-process',
            title: 'Inventory Stock Process',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_warehouse']
          },
          {
            path: '/warehouse/warehouse-inventory/replenishment-report',
            title: 'Replenishment Report',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            allowPermissions: ['view_warehouse'],
            submenu: []
          },
          {
            path: '/warehouse/warehouse-inventory/stock-quantity-report',
            title: 'Inventory Report',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            allowPermissions: ['view_warehouse'],
            submenu: []
          },
        ]
      },
    ],
  },
  {
    path: '', title: 'Admin', icon: 'fa fa-user-circle-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['view_product', 'view_brand', 'view_warehouse', 'view_cost_center', 'view_supplier', 'view_api_configuration', 'view_system_logs', 'view_permission', 'view_role', 'view_user', 'view_currency'],
    submenu: [
      {
        path: '',
        title: 'Product',
        icon: '',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/product/product',
            title: 'Product',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: []
          },
          {
            path: '/product/product-bulk-upload-list',
            title: 'Bulk Upload',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: []
          }
        ],
      },
      {
        path: '/product/brand',
        title: 'Brand',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_brand']
      },


      {
        path: '/cost-center/cost-center',
        title: 'Cost Center',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_cost_center']
      },
      {
        path: '',
        title: 'Currency',
        icon: '',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/currency/currencies',
            title: 'Currencies',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_currency']
          },
          {
            path: '/currency/processors',
            title: 'Processors',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_currency']
          }
        ],
        allowPermissions: ['view_currency']
      },
      {
        path: '/shipping-carrier/shipping-carrier-list',
        title: 'Shipping Carriers',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['handle_shipping_carriers']
      },
      {
        path: '/person/people',
        title: 'Business Partners',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_supplier']
      },
      {path: '/customer/customers', title: 'Customers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {
        path: '/api/api-configuration',
        title: 'Api Config',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_api_configuration']
      },
      {
        path: '/configuration/system-configuration',
        title: 'System Config',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_system_configuration']
      },
      {
        path: '/log/system-log',
        title: 'System Logs',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        allowPermissions: ['view_system_logs']
      },
      {
        path: '', title: 'Users', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        allowPermissions: ['view_permission', 'view_role', 'view_user'],
        submenu: [
          {
            path: '/usermanagement/permission',
            title: 'Permissions',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_permission']
          },
          {
            path: '/usermanagement/role',
            title: 'Roles',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_role']
          },
          {
            path: '/usermanagement/user',
            title: 'Users',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            allowPermissions: ['view_user']
          },
        ]
      },
    ]
  },
];
